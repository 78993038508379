import "./App.css";

import axios from "axios";
import React, { useEffect, useState } from "react";

const App = () => {
  const [mintedCount, setMintedCount] = useState(null);
  const [listedCount, setListedCount] = useState(null);
  const [floorNFT, setFloorNFT] = useState(null);
  const [mintedError, setMintedError] = useState(null);
  const [listedError, setListedError] = useState(null);
  const [daysSinceLastMint, setDaysSinceLastMint] = useState(null);
  const [daysSinceLastSale, setDaysSinceLastSale] = useState(null);
  const [loadingMinted, setLoadingMinted] = useState(true);
  const [loadingListed, setLoadingListed] = useState(true);
  const [loadingDaysSinceMint, setLoadingDaysSinceMint] = useState(true);
  const [loadingDaysSinceSale, setLoadingDaysSinceSale] = useState(true);

  const BACKEND_URL = "https://sealana-stats-backend.tehwolf.de";

  useEffect(() => {
    const fetchMintedCount = async () => {
      try {
        const response = await axios.get(BACKEND_URL + "/api/minted");
        setMintedCount(response.data.mintedCount);
      } catch (err) {
        setMintedError("Failed to fetch minted data");
      } finally {
        setLoadingMinted(false);
      }
    };

    const fetchListedData = async () => {
      try {
        const response = await axios.get(BACKEND_URL + "/api/listed");
        setListedCount(response.data.listedCount);
        setFloorNFT(response.data.floorNFT);
      } catch (err) {
        setListedError("Failed to fetch listed data");
        setListedCount(null);
        setFloorNFT(null);
      } finally {
        setLoadingListed(false);
      }
    };

    const fetchDaysSinceLastMint = async () => {
      try {
        const response = await axios.get(
          BACKEND_URL + "/api/days-since-last-mint"
        );
        setDaysSinceLastMint(response.data.daysSinceLastMint);
      } catch (err) {
        console.error("Failed to fetch days since last mint:", err);
      } finally {
        setLoadingDaysSinceMint(false);
      }
    };

    const fetchDaysSinceLastSale = async () => {
      try {
        const response = await axios.get(
          BACKEND_URL + "/api/days-since-last-sale"
        );
        setDaysSinceLastSale(response.data.daysSinceLastSale);
      } catch (err) {
        console.error("Failed to fetch days since last sale:", err);
      } finally {
        setLoadingDaysSinceSale(false);
      }
    };

    fetchMintedCount();
    fetchListedData();
    fetchDaysSinceLastMint();
    fetchDaysSinceLastSale();
  }, []);

  const renderSpinner = () => <div className="spinner"></div>;

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="header-text">SEALANA</h1>
        <h1 className="header-text">STATS</h1>

        {loadingMinted ? (
          renderSpinner()
        ) : (
          <>
            {mintedError && <p className="error-message">{mintedError}</p>}
            <div className="section">
              <div className="section-title">MINTED</div>
              <div className="numbers">{mintedCount}</div>
            </div>
          </>
        )}

        {loadingListed ? (
          renderSpinner()
        ) : (
          <>
            {listedError && <p className="error-message">{listedError}</p>}
            <div className="section">
              <div className="section-title">LISTED</div>
              <div className="numbers">{listedCount}</div>
            </div>
          </>
        )}

        {floorNFT && (
          <div className="nft-container">
            <a
              href={"https://magiceden.io/marketplace/sealana"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={floorNFT.image} alt="Floor NFT" className="nft-image" />
            </a>
            <p className="floor-price">
              Adopt this seal today for just {floorNFT.price}!
            </p>
          </div>
        )}

        {loadingDaysSinceMint ? (
          renderSpinner()
        ) : (
          <div className="gradient-text">
            {daysSinceLastMint} Days Since Last Mint
          </div>
        )}

        {loadingDaysSinceSale ? (
          renderSpinner()
        ) : (
          <div className="gradient-text">
            {daysSinceLastSale} Days Since Last Sale
          </div>
        )}
      </header>
    </div>
  );
};

export default App;
